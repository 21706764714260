<template>
  <div>
    <loader v-if="loading"></loader>
    <div v-else class="align">
      <div class="contentBlock"
        v-if="activeDealData('DealInfo').DlCode !== undefined && activeDealData('DealInfo').StateDeal === 0">
        <div class="title">
          <h3>Сумма к оплате</h3>
        </div>
        <div class="body">
          <div class="inputBox">
            <input placeholder="Введите сумму" type="tel" id="amount" v-model.trim="dealObj.CurPay" v-mask="'######'">
            <label for="amount">Сумма к оплате</label>
            <div v-show="erramount.length > 0" v-html="erramount" class="errMsg"></div>
          </div>
        </div>

        <div class="title">
          <h3>Выберите способ оплаты:</h3>
        </div>
        <div class="body" id="selectorGet">
          <div v-for="(item, index) in tinkoffE2C" :key="index" class="item"
            :class="{ selected: item.cardId === paymentSelected.cardId }">
            <label :for="'card_' + index">
              <input type="radio" name="paymentType" v-model="paymentSelected" :value="item" :id="'card_' + index">
              <div class="cardView">
                <img svg-inline :src=item.cardType alt="" class="falseSvg">
                <span>{{ item.pan }}</span>
              </div>

            </label>
          </div>
          <!-- <div class="item" @click="addTinkoffE2C" :class="{ selected: paymentSelected.cardId === 'newCard' }">
            <label for="newCard">
              <input type="radio" name="paymentType" v-model="paymentSelected" :value="{ cardId: 'newCard' }"
                id="newCard">
              <span>Добавить новую карту</span>
            </label>
          </div> -->
          <div class="item" @click="showPaymentSystem" :class="{ selected: paymentSelected.cardId === 'paymentSystem' }">
            <label for="paymentSystem">
              <input type="radio" name="paymentType" v-model="paymentSelected" :value="{ cardId: 'paymentSystem' }"
                id="paymentSystem">
              <span>Оплатить банковской картой</span>
            </label>
          </div>
        </div>
      </div>
      <div class="contentBlock" v-if="!!paymentSelected.cardId && erramount.length === 0">

        <iframe id="tkFrame" :src="addTinkoffE2CURL" frameborder="0" v-if="paymentSelected.cardId === 'newCard'"
          style="width: 100%;height: 600px"></iframe>

        <div v-else-if="paymentSelected.cardId === 'paymentSystem' && availablePaymentSystem && dealObj.CurPay > 0">
          <loader v-if="actionLoading"></loader>
          <div v-else class="title">
            <h3>Оплата</h3>
          </div>

          <component :dealObj="dealObj" class='body' id="psFrame" :is="'payment' + availablePaymentSystem.formParam.name"></component>
        </div>

        <div id="pay" v-else-if="tinkoffE2C.length > 0">
          <button @click="makePayment">
            Оплатить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getTinkoffE2C, addTinkoffE2C } from '@/api/tinkoffApi'

export default {
  name: "payment",
  components: {
    loader: () => import('@/components/other/loader'),
    paymentZk: () => import('./paymentZk'),
    // paymentQiwi: () => import('./paymentQiwi'),
    paymentZkLink: ()=>import('./paymentZkLink'),
    paymentTinkoff: () => import('./paymentTinkoff'),
  },
  props: {
    // из роута
    paymentBy: {
      type: String,
      required: true,
      validator: (value) => ['current', 'longation'].includes(value)
    }
  },
  data() {
    return {
      loading: true,
      actionLoading: false,
      dealObj:{
        DlCode: '00',
        CurPay: 0
      },
      tinkoffE2C: [],
      addTinkoffE2CURL: '',
      paymentSystems: [],
      cardIcon: {
        masterCard: require('@/assets/vector/cardTypesLogo/Mastercard.svg'),
        MIR: require('@/assets/vector/cardTypesLogo/MIR.svg'),
        visa: require('@/assets/vector/cardTypesLogo/visa.svg'),
        AmericanExpress: require('@/assets/vector/cardTypesLogo/amex.svg'),
        JCB: require('@/assets/vector/cardTypesLogo/JCB.svg'),
        International: require('@/assets/vector/cardTypesLogo/ICS.svg'),
        DinersClub: require('@/assets/vector/cardTypesLogo/dinersClub.svg'),
        Maestro: require('@/assets/vector/cardTypesLogo/maestro.svg'),
        Discover: require('@/assets/vector/cardTypesLogo/discover.svg'),
        UEK: require('@/assets/vector/cardTypesLogo/Mastercard.svg'),
        chinaUnionPay: require('@/assets/vector/cardTypesLogo/chinaUnionPay.svg')
      },
    }
  },
  watch: {
    addTinkoffE2CURL(newValue) {
      if (newValue.length > 0) {
        this.tryCathUpdateFromTinkof();
      }
    },
  },
  computed: {
    ...mapState({
      ActiveDemand: state => state.contentData.deals.activeDemand,
      loadingDeals: state => state.contentData.deals.isLoading,
      vuexPaymentSelected: state => state.Form.paymentData.paymentSelected,
    }),

    ...mapGetters([
      'activeDealData',
      'paySum'
    ]),
    erramount() {
      if(parseInt(this.dealObj.CurPay, 10) <= 0) {
        return 'Некорректная сумма';
      }
      return this.dealObj.Is_pdl && (parseInt(this.dealObj.CurPay, 10) > Math.ceil(this.paySum)) 
        ? 'Сумма доступна только для оплаты меньше или равной ' + this.paySum 
        : '';
    },
    paymentSelected: {
      get() {
        return this.vuexPaymentSelected;
      },
      set(newValue) {
        this.$store.dispatch('savePaymentData', {
          target: 'paymentSelected',
          value: newValue
        });
      }
    },
    availablePaymentSystem() {
      //при формировании ссылки на платеж работает правило – если сумма займа менее 60000р, то тинькофф, иначе – ЗК
      // if(parseInt(this.activeDealData('DealInfo').dualDealSumm) >= 60000 && this.activeDealData('DealInfo').Is_pdl == 0){
      //   return {"formId":666,"formName":"форма оплаты зол. корона","formParam":{"url":"","name":"ZkLink","available":"true"}}
      // }
      return this.paymentSystems.find(x => x.formParam.available === 'true')
      // return this.paymentSystems.find(x => x.formParam.name === 'Qiwi')
    }
  },
  async mounted() {
    this.$store.dispatch('savePaymentData', {
      target: 'paymentBy',
      value: this.paymentBy
    });
    try {
      this.clientId = parseInt(this.activeDealData('DealInfo').client_id);
      if (this.clientId <= 0 || isNaN(this.clientId)) {
        throw new Error('Отсутствует cid. Невозможно подключиться к платежной системею. Номер договора ' + this.activeDealData('DealInfo').DlCode);
      }

      if (this.paymentBy === 'longation') {
        const { Success } = await this.$external.universalRequest({
          action: 'pdl_SetRenewalFlag',
          externalType: 'zita',
          demid: parseInt(this.activeDealData('DealInfo').DlCode, 10)
        })
        if (Success !== 1) {
          throw new Error('Пролонгирование не может быть активированно в данный момент. Попробуйте позднее.')
        }
      }

      await this.getTinkoffE2C();

      const { result } = await this.$myApi.singleRequest({
        action: 'paymentPages',
        type: 'Qiwi'
      })
      this.paymentSystems = result
    } catch (e) {
      alert(e)
      this.$router.push('/')
    }
    this.dealObj.CurPay = this.dealObj.Is_pdl == 1 ? this.paySum : ''
    this.dealObj.DlCode = this.activeDealData('DealInfo').DlCode
    this.dealObj.ClientPhone = this.activeDealData('DealInfo').ClientPhone
    this.dealObj.ClientFIO = this.activeDealData('DealInfo').ClientFIO
    this.dealObj.Is_pdl = this.activeDealData('DealInfo').Is_pdl
    this.loading = false
    window.scrollTo(0, 0);
  },
  methods: {
    async getTinkoffE2C() {
      try {
        const res = await getTinkoffE2C(this.clientId)
        this.tinkoffE2C = res.result.cardList;
        for (let i = 0; i < this.tinkoffE2C.length; i++) {
          let firstFour = this.tinkoffE2C[i].pan.slice(0, 4)
          this.tinkoffE2C[i]['cardType'] = this.cardTypeIdentify(firstFour);
        }
      } catch (e) {
        alert('Невозможно получить сохраненные карты.');
        this.$router.push('/')
      }
    },
    async addTinkoffE2C() {
      try {
        const res = await addTinkoffE2C(this.clientId)
        this.addTinkoffE2CURL = res.result.paymentURL;
        setTimeout(() => {
          document.getElementById('tkFrame').scrollIntoView({
            behavior: 'smooth',
          });
        }, 300)
      } catch (e) {
        alert('Невозможно добавить катру.');
        this.$router.push('/')
      }
    },
    showPaymentSystem() {
      setTimeout(() => {
        document.getElementById('psFrame').scrollIntoView({
          behavior: 'smooth',
        });
      }, 600)
    },
    makePayment() {
      if (this.erramount.length > 0) {
        alert(this.erramount);
        return false;
      }
      this.actionLoading = true;

      let paymentData = {
        PAYMENT_TYPE: this.activeDealData('DealInfo').PAYMENT_TYPE,
        demand: this.ActiveDemand,
        tinkoffE2C_cardSelected: this.activeDealData('DealInfo').tinkoffE2C_cardSelected,
        amount: parseInt(this.dealObj.CurPay, 10)
      };
      this.$external.universalRequest({
        externalType: 'zita',
        action: 'paymentObject',
        JsonData: {
          userId: localStorage.getItem('UserID'),
          sessionKey: localStorage.getItem('SessionKey'),
          source: 'lkClient',
          type: 'recurrent',
          paymentData: paymentData
        }
      }).then(res => {
        console.log(res);
        alert('Оплата проведена');
      }).catch(err => {
        alert(err.errorMsg);
      }).finally(() => {
        this.actionLoading = false;
      });
    },
    cardTypeIdentify(value) {
      let out = ''
      let deep = value.slice(1, 2)
      switch (value.slice(0, 1)) {
        case '2': {
          out = this.cardIcon.MIR
        } break;
        case '3': {
          if (deep === '0' || deep === '6' || deep === '8') {
            out = this.cardIcon.DinersClub
          } else if (deep === '1' || deep === '5') {
            out = this.cardIcon.International
          } else if (deep === '4' || deep === '7') {
            out = this.cardIcon.AmericanExpress
          }
        } break;
        case '4': {
          out = this.cardIcon.visa
        } break;
        case '5': {
          if (deep === '0' || deep === '6' || deep === '7' || deep === '8') {
            out = this.cardIcon.Maestro
          } else if (deep === '1' || deep === '2' || deep === '3' || deep === '4' || deep === '5') {
            out = this.cardIcon.masterCard
          }
        } break;
        case '6': {
          if (deep === '0') {
            out = this.cardIcon.Discover
          } else if (deep === '2') {
            out = this.cardIcon.chinaUnionPay
          } else if (deep === '3' || deep === '7') {
            out = this.cardIcon.Maestro
          }
        } break;
        case '7': {
          out = this.cardIcon.UEK
        } break;
        default: out = ''
      }
      // out = this.cardIcon.Discover
      return out
    },
    tryCathUpdateFromTinkof() {
      if (this.addTinkoffE2CURL.length > 0) {
        this.getTinkoffE2C().then(() => {
          setTimeout(() => {
            this.tryCathUpdateFromTinkof();
          }, 3000);
        })
      }
    }
  }
}
</script>

<style scoped>

  .item { width: 100%; height: 18vw; border: 1px solid transparent;border-radius: 9px;box-shadow: 0 4px 4px #00000040;transition: 0.3s ease-out;cursor: pointer;margin-bottom: 2.94vw }
  .item:hover, .item.selected {background: rgba(67, 172, 52, 0.25);border:1px solid #43AC34}
  .item label {display: flex;align-items: center;justify-content: space-between; height: 100%; cursor: pointer; padding: 0 5vw;}
  .cardView {display: flex;flex-direction: column;align-items: flex-end;justify-content: space-around;height: 100%; width: 62vw}
  .cardView img {width: 22.06vw;height: 17.35vw}
  #selectorGet {display: flex;align-items: center;justify-content: space-between; flex-direction: column;}
  #pay { display: flex; }
  #pay button { width: 100%; }
  @media (min-width: 760px) {
    .item{margin-bottom: 0.81vw}
    .item:hover, .item.selected {}
    .item label{}

    .cardView{ width: 16.27vw }
    .cardView img{width: 75px;height: 59px}
    #pay { justify-content: end; }
    #pay button { width: 150px; }
  }
  @media (min-width: 1240px) {
    #selectorGet {flex-direction: row; flex-wrap: wrap; justify-content: space-between; }

    .item{width: 49%; height: 100px; margin-bottom: 10px}
    .item:hover, .item.selected {}
    .item label{}

    .cardView{ width: 196px }
    .cardView img{width: 75px;height: 59px}
  }
</style>
