import axios from 'axios';

const msUrl = process.env.VUE_APP_URL_API_MS;

export const getTinkoffE2C = async function (clientId) {
    const { data } = await axios.post(`${msUrl}/getTinkoffE2C.php`, { clientId });
    return data;
  }
  
  export const getTinkoffA2C = async function (clientId) {
    const { data } = await axios.post(`${msUrl}/getTinkoffA2C.php`, { clientId });
    return data;
  }
  
  export const addTinkoffE2C = async function (clientId) {
    const { data } = await axios.post(`${msUrl}/addTinkoffE2C.php`, { clientId });
    return data;
  }